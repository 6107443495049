import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["container", "selectedTeams", "options", "hiddenInput", "dropdownInput"]
  static values = {
    url: String
  }

  connect() {
    this.isTeamSelect = this.hasContainerTarget && this.hasSelectedTeamsTarget
    this.isInviteTable = this.hasUrlValue

    if (this.isTeamSelect) {
      this.initializeTeamSelect()
    }
    
    if (this.isInviteTable) {
      this.initializeInviteTable()
    }
  }

  initializeTeamSelect() {
    this.selectedTeams = new Set()
    this.boundCloseDropdown = this.closeDropdown.bind(this)
    document.addEventListener('click', this.boundCloseDropdown)
    this.containerTarget.addEventListener('mouseleave', this.handleMouseLeave.bind(this))
  }

  initializeInviteTable() {
    this.token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
  }

  disconnect() {
    if (this.isTeamSelect) {
      document.removeEventListener('click', this.boundCloseDropdown)
      this.containerTarget.removeEventListener('mouseleave', this.handleMouseLeave)
    }
  }

  toggleDropdown(event) {
    if (!this.isTeamSelect) return
    event.stopPropagation()
    this.optionsTarget.classList.toggle("show")
  }

  closeDropdown(event) {
    if (!this.isTeamSelect) return
    if (!this.containerTarget.contains(event.target)) {
      this.optionsTarget.classList.remove("show")
    }
  }

  handleMouseLeave() {
    if (!this.isTeamSelect) return
    this.optionsTarget.classList.remove("show")
  }

  toggleTeam(event) {
    if (!this.isTeamSelect) return
    const teamId = event.currentTarget.dataset.teamId

    if (this.selectedTeams.has(teamId)) {
      this.selectedTeams.delete(teamId)
      event.currentTarget.classList.remove("selected")
    } else {
      this.selectedTeams.add(teamId)
      event.currentTarget.classList.add("selected")
    }

    this.updateSelectedTeams()
  }

  updateSelectedTeams() {
    if (!this.hasSelectedTeamsTarget) return

    this.selectedTeamsTarget.innerHTML = Array.from(this.selectedTeams).map(teamId => {
      const teamOption = this.optionsTarget.querySelector(`[data-team-id="${teamId}"]`)
      if (!teamOption) return ''
      const teamName = teamOption.textContent.trim()
      return `<span class="team-pill" data-team-id="${teamId}">
        ${teamName}
        <button class="remove-team" data-action="click->invites#removeTeam">&times;</button>
      </span>`
    }).join('')

    if (this.hasHiddenInputTarget) {
      this.hiddenInputTarget.value = Array.from(this.selectedTeams).join(',')
    }
  }

  removeTeam(event) {
    if (!this.isTeamSelect) return
    event.preventDefault()
    const teamId = event.currentTarget.closest('.team-pill').dataset.teamId
    this.selectedTeams.delete(teamId)
    this.optionsTarget.querySelector(`[data-team-id="${teamId}"]`).classList.remove("selected")
    this.updateSelectedTeams()
  }

  checkAllCheckboxes(event) {
    if (!this.isInviteTable) return
    event.preventDefault()
    const { currentTarget: link } = event
    const cmd = link.textContent

    this.checkboxes(checkbox => {
      checkbox.checked = cmd === 'Select All'
    })
    link.textContent = cmd === 'Select All' ? 'Deselect All' : 'Select All'
  }

  makeDeliveries(event) {
    if (!this.isInviteTable) return
    event.preventDefault()

    const checked = [...document.querySelectorAll('input.invite-trigger:checked:enabled')]
    const invites = checked.map(checkbox => checkbox.getAttribute('value'))

    if (invites.length > 0 && this.urlValue) {
      fetch(this.urlValue, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.token,
        },
        body: JSON.stringify({invites}),
      }).then(() => {
        checked.forEach(checkbox => checkbox.disabled = true)
      }).finally(() => {
        setTimeout(() => {
          checked.forEach(checkbox => {
            const indicator = document.createElement('span')
            indicator.classList.add('text-small')
            indicator.textContent = 'Invitation Sent'
            checkbox.replaceWith(indicator)
          })
        }, 450)
      })
    }
  }

  checkboxes(fn) {
    document.querySelectorAll('input.invite-trigger:enabled').forEach(fn)
  }
}