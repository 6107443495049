// app/javascript/controllers/client_credentials_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submitButton", "clientId", "clientSecret", "loginButton","configData"];

  
  connect() {
    const configDataCheck = this.configDataTarget.dataset
    this.clientIdSaved = configDataCheck.clientIdSaved === "true";
    this.clientSecretSaved = configDataCheck.clientSecretSaved === "true";
    this.checkFormValidity();
  }

  checkFormValidity() {
    // TODO: Trying to iron out conflicting documentation if one or both actually has a requirement of 32 or greater in length
    const CLIENT_ID_MIN_LENGTH = 8
    const CLIENT_SECRET_MIN_LENGTH = 8
    const clientId = this.clientIdTarget.value.trim();
    const clientSecret = this.clientSecretTarget.value.trim();

    const isClientIdValid = (clientId !== "" && clientId.length >= CLIENT_ID_MIN_LENGTH);
    const isClientSecretValid = (clientSecret !== "" && !clientSecret.includes("*") && clientSecret.length >= CLIENT_SECRET_MIN_LENGTH);
    const isConfigPresent = this.clientIdSaved && this.clientSecretSaved;

    if (isClientIdValid && isClientSecretValid) {
      this.submitButtonTarget.removeAttribute("disabled");
    } else {
      this.submitButtonTarget.setAttribute("disabled", true);
    }

    if (isConfigPresent) {
      this.loginButtonTarget.removeAttribute("disabled");
    } else {
      this.loginButtonTarget.setAttribute("disabled", true);
    }
  }

  // Listen for input events on clientId and clientSecret to validate form
  validate() {
    this.checkFormValidity();
  }
}
