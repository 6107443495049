import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import tippy from "tippy.js";

const ENVIRONMENT = document.querySelector('meta[name="rails-environment"]')?.content || 'production'
const STATE_STORAGE_KEY = ENVIRONMENT === 'production' 
  ? 'connectorDashboardState' 
  : `${ENVIRONMENT}ConnectorDashboardState`
export default class extends Controller {
  static targets = [
    'defaultSort', 'backupSort', 'personDropdown', 'paginationCompanies', 'paginationPeople', 'companyList',
    'personList', 'tabs', 'relationshipPercentage', 'peopleGuideTextCta', 'peopleAddRelationshipsCta',
    'pageHeading', 'personHide', 'companyHide', 'tabsNav'
  ]
  loading = false;

  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    const params = new URLSearchParams(window.location.search)
    const tabParam = params.get('tab')
    const pageParam = params.get('page')

    if (tabParam && pageParam) {
      this.showLoadingState(tabParam)

      sessionStorage.setItem('connectorDashboardLoading', 'true')
      let savedState = this.loadStateFromStorage()
      savedState[tabParam].states[savedState[tabParam].activeSort].page = pageParam
      this.saveStateToStorage()

      window.location.search = `?tab=${tabParam}`
      return
    }

    if (sessionStorage.getItem('connectorDashboardLoading')) {
      sessionStorage.removeItem('connectorDashboardLoading')
      this.handlePostRedirect(tabParam, pageParam)
    } else {
      this.initializeState(tabParam, pageParam)
    }
  }

  initializeState(tabParam, pageParam) {
    try {
      let savedState = this.loadStateFromStorage()
      const defaultSort = this.getDefaultCompanySort()
      
      const DEFAULT_STATE = {
        companies: {
          activeSort: defaultSort,
          showHidden: false,
          states: {
            Relevance: { page: '1' },
            'High Priority': { page: '1' },
            Alphabetical: { page: '1' }
          }
        },
        people: {
          activeSort: 'Relevance',
          showHidden: false,
          states: {
            Relevance: { page: '1' },
            Alphabetical: { page: '1' }
          }
        },
        activeTab: 'companies'
      }
      
      if (tabParam && pageParam) {
        this.state = {
          ...DEFAULT_STATE,
          activeTab: tabParam,
          [tabParam]: {
            ...DEFAULT_STATE[tabParam],
            states: {
              ...DEFAULT_STATE[tabParam].states,
              [DEFAULT_STATE[tabParam].activeSort]: {
                page: pageParam
              }
            }
          }
        }
      }
      else if (tabParam) {
        this.state = {
          ...savedState,
          activeTab: tabParam
        }
      }
      else {
        this.state = this.resolveState(savedState, DEFAULT_STATE)
      }

      if (this.pageHeadingTarget && this.state.activeTab) {
        this.updatePageHeading(this.state.activeTab)
      }

      this.saveStateToStorage()
      this.applyCurrentState()
    } catch (error) {
      console.warn('State initialization error:', error)
      const defaultSort = this.getDefaultCompanySort()
      
      // Create a complete default state object in the error case
      this.state = {
        companies: {
          activeSort: defaultSort,
          showHidden: false,
          states: {
            Relevance: { page: '1' },
            'High Priority': { page: '1' },
            Alphabetical: { page: '1' }
          }
        },
        people: {
          activeSort: 'Relevance',
          showHidden: false,
          states: {
            Relevance: { page: '1' },
            Alphabetical: { page: '1' }
          }
        },
        activeTab: 'companies'
      }
      
      this.applyCurrentState()
    }
  }

  resolveState(savedState, defaultState) {
    const params = new URLSearchParams(window.location.search)
    const urlTab = params.get('tab')

    if (!defaultState) {
      const availableSort = this.getDefaultCompanySort()
      defaultState = {
        companies: {
          activeSort: availableSort,
          showHidden: false,
          states: {
            Relevance: { page: '1' },
            'High Priority': { page: '1' },
            Alphabetical: { page: '1' }
          }
        },
        people: {
          activeSort: 'Relevance',
          showHidden: false,
          states: {
            Relevance: { page: '1' },
            Alphabetical: { page: '1' }
          }
        },
        activeTab: 'companies'
      }
    }
  
    const availableSort = this.getDefaultCompanySort()
    return {
      companies: {
        activeSort: this.verifyAvailableSort(savedState?.companies?.activeSort) || availableSort,
        showHidden: savedState?.companies?.showHidden ?? defaultState.companies.showHidden,
        states: {
          ...defaultState.companies.states,
          ...(savedState?.companies?.states || {})
        }
      },
      people: {
        activeSort: savedState?.people?.activeSort || defaultState.people.activeSort,
        showHidden: savedState?.people?.showHidden ?? defaultState.people.showHidden,
        states: {
          ...defaultState.people.states,
          ...(savedState?.people?.states || {})
        }
      },
      activeTab: urlTab || savedState?.activeTab || defaultState.activeTab
    }
  }

  loadStateFromStorage() {
    try {
      const savedState = localStorage.getItem(STATE_STORAGE_KEY)
      if (!savedState || savedState === 'undefined') {
        return this.createInitialState()
      }
      const parsedState = JSON.parse(savedState)
      return this.validateStateStructure(parsedState)
    } catch (error) {
      console.warn('State loading failed:', error)
      return this.createInitialState()
    }
  }

  saveStateToStorage() {
    try {
      localStorage.setItem(STATE_STORAGE_KEY, JSON.stringify(this.state))
    } catch (error) {
      console.warn('Error saving state to storage:', error)
    }
  }
  
  createInitialState() {
    const params = new URLSearchParams(window.location.search)
    const urlTab = params.get('tab')
    const defaultSort = this.getDefaultCompanySort()
    return {
      companies: {
        activeSort: defaultSort,
        showHidden: false,
        states: {
          Relevance: { page: '1' },
          'High Priority': { page: '1' },
          Alphabetical: { page: '1' }
        }
      },
      people: {
        activeSort: 'Relevance',
        showHidden: false,
        states: {
          Relevance: { page: '1' },
          Alphabetical: { page: '1' }
        }
      },
      activeTab: urlTab || 'companies'
    }
  }

  handlePostRedirect(tabParam, pageParam = null) {
    try {
      const savedState = this.loadStateFromStorage()
      savedState.activeTab = tabParam
    
      this.state = savedState
      this.updateImmediateUI(tabParam)
      return this.loadTabData(tabParam)
    } catch (error) {
      console.error('Post-redirect handling failed:', error)
      return this.initializeState(tabParam, pageParam)
    }
  }

  updateImmediateUI(tab) {
    this.setActiveTab(tab)
    this.syncTabUI(tab)

    if (this.hasPageHeadingTarget) {
      this.pageHeadingTarget.textContent = tab
    }

    const listTarget = tab === 'companies' ? this.companyListTarget : this.personListTarget
    const paginationTarget = tab === 'companies' ? 
      this.paginationCompaniesTarget : 
      this.paginationPeopleTarget
    
    if (listTarget) listTarget.innerHTML = ''
    if (paginationTarget) paginationTarget.innerHTML = ''
    
    const loader = document.querySelector(`.${tab}-loader`)
    if (loader) {
      const parentNode = loader.parentNode
      if (parentNode) {
        const classList = parentNode.classList
        if (classList) classList.remove('hide')
      }
    }
  }

  validatePageNumber(page) {
    const pageNum = parseInt(page, 10)
    return (pageNum > 0) ? pageNum.toString() : '1'
  }

  validateStateStructure(state) {
    const defaultState = this.createInitialState()
    return {
      companies: {
        activeSort: this.verifyAvailableSort(state?.companies?.activeSort) || defaultState.companies.activeSort,
        showHidden: !!state?.companies?.showHidden,
        states: { ...defaultState.companies.states, ...(state?.companies?.states || {}) }
      },
      people: {
        activeSort: state?.people?.activeSort || defaultState.people.activeSort,
        showHidden: !!state?.people?.showHidden,
        states: { ...defaultState.people.states, ...(state?.people?.states || {}) }
      },
      activeTab: state?.activeTab || defaultState.activeTab
    }
  }

  getRequestParams(tab) {
    const activeSort = this.state[tab].activeSort
    const currentPage = this.state[tab].states[activeSort]?.page || '1'

    return {
      company_sort: activeSort,
      page: currentPage
    }
  }

  async applyCurrentState() {
    const { activeTab } = this.state
  
    this.syncInitialVisibility(activeTab)
  
    this.setActiveTab(activeTab)
    this.syncTabUI(activeTab)

    await this.loadTabData(activeTab)
  }

  async fetchWithParams(url, params = {}) {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        type: 'GET',
        url,
        dataType: 'json',
        data: new URLSearchParams(params).toString(),
        success: (data) => resolve(data),
        error: (error) => reject(error)
      })
    })
  }

  async sortCompany(event) {
    event.preventDefault()
    const sortBy = event.currentTarget.getAttribute('data-sort-value')

    this.state.companies.activeSort = sortBy
    this.saveStateToStorage()

    await this.loadTabData('companies')
    this.updateCompanySortUI(this.state.companies.activeSort)
  }

  async sortPeople(event) {
    event.preventDefault()
    const sortBy = event.target.value

    this.state.people.activeSort = sortBy
    this.saveStateToStorage()

    await this.loadTabData('people')
    this.updatePeopleSortUI(this.state.people.activeSort)
  }

  async loadTabData(tab) {
    const activeSort = this.state[tab].activeSort
    const currentPage = this.state[tab].states[activeSort]?.page || '1'
  
    const params = {
      company_sort: activeSort,
      page: currentPage
    }

    try {
      const data = await this.fetchWithParams(`/dashboard/${tab}`, params)
      if (tab === 'companies') {
        this.fillCompaniesData(data)
      } else {
        this.fillPeopleData(data)
      }
    } catch (error) {
      console.error(`Error loading ${tab} data:`, error)
    }
  }

  async switchTabs(event) {
    const targetTab = event.target.id.replace('nav-', '').replace('-tab', '')

    this.showLoadingState(targetTab)
    this.updatePageHeading(targetTab)

    this.state.activeTab = targetTab
    this.saveStateToStorage()

    window.location.search = `?tab=${targetTab}`
  }


  getDefaultCompanySort() {
    try {
      const availableSorts = Array.from(this.element.querySelectorAll('[data-sort-value]'))
        .filter(el => !el.parentElement.hasAttribute('style'))
        .map(el => el.getAttribute('data-sort-value'))
  
      if (availableSorts.length === 1) {
        return 'Alphabetical'
      }
  
      const relevanceSort = this.defaultSortTarget
      const relevanceFlag = relevanceSort?.dataset?.relevanceFlag === 'true'
      
      if (relevanceFlag && !relevanceSort.parentElement.hasAttribute('style')) {
        return relevanceSort.getAttribute('data-sort-value')
      }
      
      const highPrioritySort = availableSorts.includes('High Priority') ? 'High Priority' : null
      if (highPrioritySort) {
        return highPrioritySort
      }
      
      return this.backupSortTarget?.getAttribute('data-sort-value') || 'Alphabetical'
    } catch (error) {
      console.warn('Error getting default company sort:', error)
      return 'Alphabetical'
    }
  }

  setActiveTab(tab) {
    this.tabsNavTarget.querySelectorAll('.nav-link').forEach(tabElement => {
      tabElement.classList.remove('active')
    })

    document.querySelectorAll('.tab-pane').forEach(pane => {
      pane.classList.remove('active', 'show')
    })

    const tabButton = this.tabsNavTarget.querySelector(`#nav-${tab}-tab`)
    const tabContent = document.querySelector(`#nav-${tab}`)

    if (tabButton && tabContent) {
      tabButton.classList.add('active')
      tabContent.classList.add('show', 'active')
    }
  }

  showLoadingState(tab) {
    const listTarget = tab === 'companies' ? this.companyListTarget : this.personListTarget
    const paginationTarget = tab === 'companies' ? 
      this.paginationCompaniesTarget : 
      this.paginationPeopleTarget

    if (listTarget) listTarget.innerHTML = ''
    if (paginationTarget) paginationTarget.innerHTML = ''

    const loader = document.querySelector(`.${tab}-loader`)
    if (loader) {
      const parentNode = loader.parentNode
      if (parentNode) {
        const classList = parentNode.classList
        if (classList) classList.remove('hide')
      }
    }
  }

  syncTabUI(tab) {
    const activeSort = this.state[tab].activeSort
    if (tab === 'people') {
      this.updatePeopleSortUI(activeSort)
    } else {
      this.updateCompanySortUI(activeSort)
    }
  }

  syncInitialVisibility(tab) {
    const checkbox = document.getElementById(`show_hidden_${tab}`)
    if (checkbox) {
      checkbox.checked = this.state[tab].showHidden
      const elements = tab === 'companies' 
        ? this.companyListTarget.querySelectorAll('.target-company-card')
        : this.personListTarget.querySelectorAll('.target-profile-card')
      this.hideShowComponent(elements, `show_hidden_${tab}`)
    }
  }

  updateCompanySortUI(sortBy) {
    try {
      const sortElements = Array.from(this.element.querySelectorAll('[data-sort-value]'))
        .filter(el => {
          const anchor = el.querySelector('.sort-item')
          if (!anchor) {
            return false
          }
          return true
        })

      const visibleSortOptions = sortElements.filter(el => {
        const anchor = el.querySelector('.sort-item')
        const hasStyle = !!anchor?.style
        const display = hasStyle ? anchor.style.display : null
        const isVisible = !display || display !== 'none'

        return isVisible
      })

      this.element.querySelectorAll('.sort-item').forEach(a => a.classList.remove('active'))

      if (visibleSortOptions.length === 1) {
        const singleOption = visibleSortOptions[0]
        const anchor = singleOption.querySelector('.sort-item')
        if (anchor) {
          anchor.classList.add('active')
          const newSort = singleOption.getAttribute('data-sort-value')
          if (this.state.companies.activeSort !== newSort) {
            this.state.companies.activeSort = newSort
            this.saveStateToStorage()
          }
        }
        return
      }

      const availableSort = this.verifyAvailableSort(sortBy)
      visibleSortOptions.forEach(container => {
        const anchor = container.querySelector('.sort-item')
        if (anchor && container.getAttribute('data-sort-value') === availableSort) {
          anchor.classList.add('active')
        }
      })

    } catch (error) {
      console.error('Sort UI update error:', error)
    }
  }

  updatePeopleSortUI(sortBy) {
    if (this.personDropdownTarget) {
      this.personDropdownTarget.value = sortBy
    }

    const peopleCheckbox = document.getElementById('show_hidden_people')

    if (peopleCheckbox) {
      peopleCheckbox.checked = this.state.people.showHidden
      if (this.state.people.showHidden) {
        this.hideShowPeople()
      }
    }
  }

  updatePageHeading(tab) {
    if (this.pageHeadingTarget) {
        const validTab = tab === 'people' ? 'people' : 'companies'
        this.pageHeadingTarget.innerHTML = validTab
    }
  }

  updateUIAfterNav(tab) {
    const selector = tab === 'companies' ? '.target-company-card' : '.target-profile-card'
    const listItems = this.element.querySelectorAll(selector)
    this.hideShowComponent(listItems, `show_hidden_${tab}`)
    this.scrollToTop()
  }

  verifyAvailableSort(requestedSort) {
    try {
      if (!requestedSort) {
        return this.getDefaultCompanySort()
      }
      const sortElement = this.element.querySelector(`[data-sort-value="${requestedSort}"]`)
      if (sortElement?.parentElement && !sortElement.parentElement.hasAttribute('style')) {
        return requestedSort
      }
      return this.getDefaultCompanySort()
    } catch (error) {
      console.warn('Error verifying sort option:', error)
      return this.getDefaultCompanySort()
    }
  }

  // Hide show companies
  companyHide(event) {
    const payload = JSON.parse(event.target.dataset.value)
    if (event.target.classList.contains('hidden-card')) {
      this.companyUnhide(event)
    } else {
      Rails.ajax({
        type: 'POST',
        url: '/hidden_companies/',
        dataType: 'json',
        data: new URLSearchParams({ company_id: payload.companyId }).toString(),
        success: (data) => {
          let row = event.target.closest('.target-company-card')
          this.addClassHiddenCard(row, event)
          this.hideShowComponent([row], 'show_hidden_companies')
          this.generateToastr(`${payload.companyName} is now hidden from your list.`)
        }
      })
    }
  }

  companyUnhide(event) {
    const payload = JSON.parse(event.target.dataset.value)
    Rails.ajax({
      type: 'DELETE',
      url: '/hidden_companies/',
      dataType: 'json',
      data: new URLSearchParams({ company_id: payload.companyId }).toString(),
      success: (data) => {
        let row = event.target.parentNode.parentNode.closest('.target-company-card')
        this.removeClassHiddenCard(row, event)
        this.generateToastr(`${payload.companyName} is now visible in your list.`)
      }
    })
  }

  // Hide show people
  personHide(event) {
    const payload = JSON.parse(event.target.dataset.value)
    if (event.target.classList.contains('hidden-card')) {
      this.personUnhide(event)
    } else {
      Rails.ajax({
        type: 'POST',
        url: '/v2/dashboard/hide/',
        dataType: 'json',
        data: new URLSearchParams({
          prospect_type: 'person',
          prospect_id: payload.personId
        }).toString(),
        success: (data) => {
          let row = event.target.closest('.target-profile-card')
          this.addClassHiddenCard(row, event)
          this.hideShowComponent([row], 'show_hidden_people')
          this.generateToastr(`${payload.personFirstName} ${payload.personLastName} is now hidden from your list.`)
        }
      })
    }
  }

  personUnhide(event) {
    const payload = JSON.parse(event.target.dataset.value)
    Rails.ajax({
      type: 'POST',
      url: '/v2/dashboard/unhide/',
      dataType: 'json',
      data: new URLSearchParams({ prospect_type: 'person', prospect_id: payload.personId }).toString(),
      success: (data) => {
        let row = event.target.closest('.target-profile-card')
        this.removeClassHiddenCard(row, event)
        this.generateToastr(`${payload.personFirstName} ${payload.personLastName} is now visible in your list.`)
      }
    })
  }

  hideShowCompanies(event) {
    let companyBoxes = this.companyListTarget.querySelectorAll('.target-company-card')
    this.hideShowComponent(companyBoxes, 'show_hidden_companies')
    this.state.companies.showHidden = document.getElementById('show_hidden_companies').checked
    this.saveStateToStorage()
  }

  hideShowPeople(event) {
    let personBoxes = this.personListTarget.querySelectorAll('.target-profile-card')
    this.hideShowComponent(personBoxes, 'show_hidden_people')
    this.state.people.showHidden = document.getElementById('show_hidden_people').checked
    this.saveStateToStorage()
  }

  fetchTooltip(event) {
    const id = event.target.dataset.companyId
    event.stopImmediatePropagation()
    if (id && event.target.parentNode.querySelector('.tooltip-text .data-loader')) {
      Rails.ajax({
        type: 'get',
        url: '/dashboard/tooltip/',
        dataType: 'json',
        data: new URLSearchParams({ company_id: id }).toString(),
        success: (data) => {
          event.target.parentNode.querySelector('.tooltip-text').innerHTML = data.tooltip
        }
      })
    }
  }

  openOfferHelpModal(event) {
    event.preventDefault()
    let relationshipId = event.target.dataset.relationshipId
    var target_modal_id = event.currentTarget.classList;
    if (target_modal_id.length > 1) {
      target_modal_id = target_modal_id[0]
    }

    Rails.ajax({
      type: 'get',
      url: '/dashboard/offer_help_modal',
      dataType: 'script',
      data: new URLSearchParams({
        relationship_id: relationshipId,
        modal: target_modal_id
      }).toString(),
      success: (data) => {}
    })
  }


  openOfferHelpModalCompany(event) {
    event.preventDefault()
    let companyId = event.target.dataset.companyId
    let companyName = event.target.dataset.companyName
    var target_modal_id = event.currentTarget.classList;
    if (target_modal_id.length > 1) {
      target_modal_id = target_modal_id[0]
    }

    Rails.ajax({
      type: 'get',
      url: '/dashboard/offer_help_company',
      dataType: 'script',
      data: new URLSearchParams({
        company_id: companyId,
        company_name: companyName,
        modal: target_modal_id
      }).toString(),
      success: (data) => {}
    })
  }

  hideShowComponent(elements, className) {
    elements.forEach(row => {
      if (document.getElementById(className).checked) {
        if (row.classList.contains('hidden-card')) {
          row.classList.remove('hide')
        }
      } else {
        if (row.classList.contains('hidden-card')) {
          row.classList.add('hide')
        }
      }
    })
  }

  async pagyCompaniesNav(event) {
    event.preventDefault()
    if (this.loading) return

    const activeSort = this.state.companies.activeSort
    const url = new URL(event.target.href)
    const page = url.searchParams.get('page')

    try {
      this.loading = true

      const loader = document.querySelector('.company-loader')
      if (loader) {
        const parentNode = loader.parentNode
        if (parentNode) {
          const classList = parentNode.classList
          if (classList) classList.remove('hide')
        }
      }

      this.companyListTarget.innerHTML = ''
      this.paginationCompaniesTarget.innerHTML = ''

      this.state.companies.states[activeSort].page = this.validatePageNumber(page)
      this.saveStateToStorage()

      const params = this.getRequestParams('companies')
      const data = await this.fetchWithParams('/dashboard/companies', params)

      this.fillCompaniesData(data)
      this.updateUIAfterNav('companies')
    } catch (error) {
      console.error('Pagination error:', error)
      if (error.message?.includes('OverflowError')) {
        this.state.companies.states[activeSort].page = '1'
        this.saveStateToStorage()
        await this.loadTabData('companies')
      }
    } finally {
      this.loading = false
    }
  }

  async pagyPeopleNav(event) {
    event.preventDefault()
    if (this.loading) return

    const activeSort = this.state.people.activeSort
    const url = new URL(event.target.href)
    const page = url.searchParams.get('page')

    try {
      this.loading = true

      const loader = document.querySelector('.person-loader')
      if (loader) {
        const parentNode = loader.parentNode
        if (parentNode) {
          const classList = parentNode.classList
          if (classList) classList.remove('hide')
        }
      }

      this.personListTarget.innerHTML = ''
      this.paginationPeopleTarget.innerHTML = ''

      this.state.people.states[activeSort].page = this.validatePageNumber(page)
      this.saveStateToStorage()

      const params = this.getRequestParams('people')
      const data = await this.fetchWithParams('/dashboard/people', params)

      this.fillPeopleData(data)
      this.updateUIAfterNav('people')
    } catch (error) {
      console.error('Pagination error for people:', error)
      this.state.people.states[activeSort].page = '1'
      this.saveStateToStorage()
      await this.loadTabData('people')
    } finally {
      this.loading = false
    }
  }

  fillCompaniesData(data) {
    this.companyListTarget.innerHTML = data.targeted_companies
    this.paginationCompaniesTarget.innerHTML = data.pagination
    this.syncInitialVisibility('companies')
    this.initializeTippy()

    const loader = document.querySelector('.company-loader')
    if (loader) {
      const parentNode = loader.parentNode
      if (parentNode) {
        const classList = parentNode.classList
        if (classList) classList.add('hide')
      }
    }
  }

  fillPeopleData(data) {
    this.personListTarget.innerHTML = data.people
    this.paginationPeopleTarget.innerHTML = data.pagination
    this.togglePeopleCta(data.relationshipCount)
    this.syncInitialVisibility('people')
    this.initializeTippy()

    const loader = document.querySelector('.person-loader')
    if (loader) {
      const parentNode = loader.parentNode
      if (parentNode) {
        const classList = parentNode.classList
        if (classList) classList.add('hide')
      }
    }
  }

  togglePeopleCta(relationshipCount) {
    if (relationshipCount > 0) {
      this.peopleGuideTextCtaTarget.classList.remove('hide')
      this.peopleAddRelationshipsCtaTarget.classList.add('hide')
    } else {
      this.peopleGuideTextCtaTarget.classList.add('hide')
      this.peopleAddRelationshipsCtaTarget.classList.remove('hide')
    }
  }

  addClassHiddenCard(row, event) {
    if (row) row.classList.add('hidden-card')
    let parentNodeClasses = event.target.parentNode.classList
    if (event.target) {
      parentNodeClasses.add('hidden-card')
      event.target.classList.add('hidden-card')
    }
  }

  removeClassHiddenCard(row, event) {
    if (row) row.classList.remove('hidden-card')
    if (event.target) {
      event.target.parentNode.classList.remove('hidden-card')
      event.target.classList.remove('hidden-card')
    }
  }

  generateToastr(message) {
    toastr.remove()
    toastr.success(message)
  }

  initializeTippy() {
    tippy('[data-tippy-content]', {
      allowHTML: true,
      arrow: true,
      maxWidth: 400,
      inertia: true,
    })
  }

  scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  // For Dev Use:
  debugState() {
    try {
        const savedState = localStorage.getItem(STATE_STORAGE_KEY)
        const parsedState = savedState ? JSON.parse(savedState) : null

        console.log('Current State:', {
            memory: this.state,
            storage: parsedState,
            match: JSON.stringify(this.state) === savedState
        })
    } catch (error) {
        console.warn('Debug State Error:', error)
    }
  }
}
